import { Injectable } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { sha256 } from "js-sha256";
import { Router } from "@angular/router";
/*Custom Imports*/
import { Tmmstorage } from "../_shared/tmmstorage";
import { ModalpageComponent } from "../modalpage/modalpage.component";
import { TranslateService } from "@ngx-translate/core";
import { Capacitor } from "@capacitor/core";
import { TmmservicebrowserService } from "./tmmservicebrowser.service";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { authDetails, AuthServiceService } from './auth-service.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Constants } from "../_shared/constants.enum";

export interface Kid{
  name: string,
  dateOfBirth: string,
  gender: string,
  id? :string,
  smashMemberId?:string
}
@Injectable({
  providedIn: "root",
})
export class GeneralmethodsService {
  BehaviorSubject;
  public languageChange: Subject<any> = new Subject();

  /* Objects */
  TmmstorageClassInstance = new Tmmstorage();

  /*Single Variables*/
  secret;
  public GET_POINTS_CURSOR;
  isActiveToggleTextPassword: Boolean = true;
  num_of_stores;
  public GET_WALLET__CURSOR;
  public orderidredemption;
  public resultcoderedemption;
  public orderiddonation;
  public resultcodedonation;
  public refreshpoints = false;
  public refreshwallet = false;
  public shortCodestatus;
  public GET_NOTIFICATION_CURSOR;

  applicationLanguageValue;
  runningplatform;
  width;

  direction;

  /*Lists */
  public GET_LOYALTY_BALANCE_LIST: any = [];
  public GET_POINT_TRANSACTION_LIST: any = [];
  public GET_SOCIAL_PROMOS_LIST: any = [];
  public GET_FLYERS_LIST: any = [];
  public GET_SMASH_WALLET_URL: any = [];
  public GIFTCARD_BALANCE: any = [];
  public GET_REDEMPTION_TIERS_LIST: any = [];
  public GET_CHARITY_ORGANIZATIONS_LIST: any = [];
  public GET_WALLET_TRANSACTION_LIST: any = [];
  public GET_GIFTCARD_BALANCE_LIST: any = [];
  public REGISTERUSER: any = [];
  public GET_NOTIFICATION_LIST: any = [];
  public GET_NOTIFICATION_SIZE = 0;
  points_in_storage = [];
  wallet_in_storage = [];
  tmmserviceServiceNatice;
  public GET_KIDS_LIST = [];

  /** LANGUAGE SWITCHER */
  currentselectedcountry;
  toselectcountry;
  genericAlertOpen=false;
  locale="ar";
  constructor(
    public alertController: AlertController,
    private cookieService: CookieService,
    public modalController: ModalController,
    private router: Router,
    public loadingController: LoadingController,
    public Tmmservicebrowser: TmmservicebrowserService,
    public toastController: ToastController,
    public translate: TranslateService,
    public authService: AuthServiceService,
    private iab: InAppBrowser,
  ) {
    this.getplatform();
    this.tmmserviceServiceNatice = Tmmservicebrowser;
  }
  getLocale(): string{
    console.log("this.cookieService.get('_icl_current_language')", this.cookieService.get('_icl_current_language'));
    let languageCookie= this.cookieService.get('_icl_current_language');
    if(languageCookie ==undefined || languageCookie =='' || languageCookie== null )
      return "en";
    return this.cookieService.get('_icl_current_language');
  }
  getplatform() {
    this.runningplatform = Capacitor.getPlatform();
  }
   /* Methods for making API CALLS */
  hasLTR(s) {
    var rtlDirCheck = new RegExp("[a-zA-Z]");
    return rtlDirCheck.test(s);
  }
  async genericAlert(message) {
    //console.log("generic alert", message);
    if(!this.genericAlertOpen){
      this.genericAlertOpen=true;
     const alert = await this.alertController.create({
       message: message,
       buttons: [this.gettranslation("IONREDEEMPOINTS_OKAY")],
     });
     await alert.present();
     alert.onDidDismiss().then(()=>{
      this.genericAlertOpen=false;
     })
  }
  }
  // Login method
  async LOGIN_USER(username: string, password: string): Promise<any> {
    let returnstatus;
    const loading = await this.loadingController.create();
    loading.present();
      await this.tmmserviceServiceNatice.LOGIN(username, password).then(async result => {
        loading.dismiss();
        if (result.status == 0) {
          //console.log("result.data?", result.data);
          let data = result.data;
          // result.data
         
          if(this.authService.authDetails==null){
            //console.log("mpeni damesa?");
            this.authService.authDetails={
              secret:data.responseBody.secret,
              orgId:'628001'
            }
            //console.log("Setting up", this.authService.authDetails);
          }else{
            //console.log("mpeni sto else??")
            this.authService.authDetails.secret=data.responseBody.secret;
          }
          //console.log("authdets meta?", this.authService.authDetails);
          this.authService.isLoggedIn=true;
          this.authService.setInStorage("authDetails", this.authService.authDetails);
          // if (this.runningplatform != "web") {
          //   this.tmmserviceServiceNatice.customheaders["SID"] =this.authService.authDetails.sid;
          // }
        returnstatus= result.status;
      }
    });
    return returnstatus;

  }

  async GET_USER_PROFILE(): Promise<any> {
    let returnstatus;
      await this.tmmserviceServiceNatice.GET_SMASH_MEMBER().then(async response => {
        if (response.status == 0) {
          // let jsonData = JSON.parse(response.data.replace(/\r?\n|\r/g, ''));
          this.authService.userDetails=response.data.responseBody.smashMember;
          //console.log("jsonData.responseBody.smashMember", response.data.responseBody.smashMember);
          this.authService.setInStorage("userDetails", this.authService.userDetails);
          this.authService.refreshState={
            smashAccountNo: this.authService.userDetails.smashAccountNo,
            refreshStores: true,
            refreshFlyers: true
          };
          this.authService.isLoggedIn=true;
          this.authService.setInStorage("refreshState", this.authService.refreshState);
          //console.log("this.authService.userDetails.smashAccountNo", this.authService.userDetails.smashAccountNo);
          returnstatus=response.status;
        }else if (response.status == -1) {
          let jsonData = response.data;
          this.genericAlert(jsonData.responseDescription);
          this.VALIDATE_SESSION();
        }else {
          let jsonData = response.data;
          this.genericAlert(jsonData.responseDescription);
          returnstatus = response.status;
        }

    });
    return returnstatus;

  }

  async GET_SMASH_MEMBER_PUBLIC(country_code_and_mobile_number) {
    let loading = await this.loadingController.create();
    await loading.present();
    ////console.log("GET_SMASH_MEMBER_PUBLIC");
    let returnvalue;
    await this.tmmserviceServiceNatice
      .GET_SMASH_MEMBER_PUBLIC(country_code_and_mobile_number)
      .then(async (result) => {
        if (result.status == 0) {
          let jsonData = result.data;

          let userstatus;
          if (jsonData.responseBody.smashMember.status == "REGISTERED") {
            userstatus = "R";
          } else {
            userstatus = "U";
          }
          //this.tmmserviceServiceNatice.sid=jsonDataHeaders.sid;
          returnvalue = {
            resultstatus: result.status,
            userstatus: userstatus,
          };
        } else {
          let jsonData = result.data;
          returnvalue = {
            resultstatus: result.status,
            userstatus: "-",
          };
        }
      });
    loading.dismiss();
    return returnvalue;
  }

  async SEND_VERIFICATION_CODE(username): Promise<any> {
    let returnstatus;
    await this.tmmserviceServiceNatice
      .SEND_VERIFICATION_CODE(username)
      .then(async (result) => {
        if (result.status == 0) {
          returnstatus = result.status;
        } else {
          let jsonData = result.data;
          this.genericAlert(jsonData.responseDescription);
          returnstatus = 1;
        }
      });
    return returnstatus;
  }

  async GET_SMASH_ACCOUNT(accountype): Promise<any> {
    let returnstatus;
    await this.tmmserviceServiceNatice.GET_SMASH_ACCOUNT(accountype).then(async (result) => {
        if (result.status == 0) {
          returnstatus = result.status;
          let jsonData = result.data;
          var GET_LOYALTY_BALANCE_LIST_INITIAL =
            jsonData.responseBody.smashAccounts;
          for (let data of GET_LOYALTY_BALANCE_LIST_INITIAL) {
            if (data.smashAccountType == "LOYALTY") {
              this.GET_LOYALTY_BALANCE_LIST.balance = data.balance;
              this.GET_LOYALTY_BALANCE_LIST.currency = data.currency;
            } else if (data.smashAccountType == "WALLET") {
              this.GET_LOYALTY_BALANCE_LIST.ewallet_balance = data.balance;
              this.GET_LOYALTY_BALANCE_LIST.ewallet_currency = data.currency;
            }
          }
          // returnstatus = GET_LOYALTY_BALANCE_LIST_INITIAL;
        } else if (result.status == -1) {
          let jsonData = result.data;
          this.genericAlert(jsonData.responseDescription);
          this.VALIDATE_SESSION();
          returnstatus=result.status;
        } else {
          returnstatus = 1;
        }
    });
    //console.log("returnstatus", returnstatus);
    return returnstatus;
  }

  async VALIDATE_SESSION(): Promise<any> {
    // //console.log("validate session");
    // //console.log("this.authService.userDetails.username",this.authService.userDetails.username);
    // await this.tmmserviceServiceNatice.VALIDATE_SESSION(this.authService.userDetails.username,password ).then(async result => {
    //   if (result.status == 0) {
    //     await this.TmmstorageClassInstance.setObject("authDetails", this.authService.authDetails);
    //   }else{
        this.authService.logout();
    //     let jsonData = result.data;
    //     this.genericAlert(jsonData.responseDescription);
        this.router.navigate(['/tabs/login']);
    //   }    
    //   return result.status;
    // });          
  }
  async GET_TRANSACTIONS(accounttype, cursor): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.GET_TRANSACTIONS(accounttype, "10", cursor, this.authService.userDetails.username).then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        //need to create a template of our transaction item
        //have to iterate throught the item   
        //console.log("accounttype ", accounttype);
        if (accounttype == "LOYALTY") {
          this.GET_POINT_TRANSACTION_LIST = jsonData.responseBody.smashTransactions;
          var str = JSON.stringify(this.GET_POINT_TRANSACTION_LIST);
          str = str.replace(/\"storeId\":/g, "\"store\":");
          str = str.replace(/\"value\":/g, "\"amount\":");
          str = str.replace(/\"transactionDate\":/g, "\"date\":");
          str = str.replace(/\"transactionType\":/g, "\"trans_type\":");
          this.GET_POINT_TRANSACTION_LIST = JSON.parse(str);
          this.GET_POINTS_CURSOR = jsonData.responseBody.cursor;
          this.points_in_storage.push(...this.GET_POINT_TRANSACTION_LIST);
          this.TmmstorageClassInstance.setObject(String("Offlinepointstransactions"), this.points_in_storage);
        }
        else {
          this.GET_WALLET_TRANSACTION_LIST = jsonData.responseBody.smashTransactions;
          var str = JSON.stringify(this.GET_WALLET_TRANSACTION_LIST);
          str = str.replace(/\"storeId\":/g, "\"store\":");
          str = str.replace(/\"value\":/g, "\"amount\":");
          str = str.replace(/\"transactionDate\":/g, "\"date\":");
          str = str.replace(/\"transactionType\":/g, "\"trans_type\":");
          this.GET_WALLET_TRANSACTION_LIST = JSON.parse(str);
          //console.log(this.GET_WALLET_TRANSACTION_LIST);
          this.GET_WALLET__CURSOR = jsonData.responseBody.cursor;
          this.wallet_in_storage.push(...this.GET_WALLET_TRANSACTION_LIST);
          this.TmmstorageClassInstance.setObject(String("Offlinewallettransactions"), this.wallet_in_storage);
        }

        returnstatus = result.status;
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }
      else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }
      returnstatus = result.status;
    });
  }

  async GET_SOCIAL_PROMOS() {
    await this.tmmserviceServiceNatice.GET_SOCIAL_PROMOS().then(async result => {
      //console.log("ti einai to result? ", result);
      if (result.status == 0) {
        let jsonData = result.data
        this.GET_SOCIAL_PROMOS_LIST = jsonData.responseBody.promos;
        var str = JSON.stringify(this.GET_SOCIAL_PROMOS_LIST);
        str = str.replace(/\"imageLink\":/g, "\"image_link\":");
        this.GET_SOCIAL_PROMOS_LIST = JSON.parse(str);
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }
      else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }

    });
  }

  async UPDATE_USER_PASSWORD(newPassword, previousPassword): Promise<any> {
    var returnResult;
    await this.tmmserviceServiceNatice.UPDATE_PASSWORD(newPassword, previousPassword).then(async result => {
      if (result.status == 0) {
        returnResult = result;
      }
      else if (result.status == -1) {
        //console.log(result.headers.sid);
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        returnResult = result;
      }
    });
    return returnResult;
  }

  async USER_LOGOUT() {
    let loading = await this.loadingController.create();
    await loading.present();
    await this.tmmserviceServiceNatice.LOGOUT().then(async result => {
      loading.dismiss();
      if (result.status == 0) {
        this.authService.logout();
        this.TmmstorageClassInstance.setStorageValue(String("LoadedMessages"), 0);
        this.router.navigate(['/tabs/login']);
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }
    });
  }
  async HANDLE_RESET_PASSWORD(username) {
    var returnstatus;
    await this.tmmserviceServiceNatice
      .RESET_PASSWORD(username)
      .then((result) => {
        if (result.status != 0) {
          let jsonData = result.data;
          this.genericAlert(jsonData.responseDescription);
        }
        returnstatus = result.status;
      });
    return returnstatus;
  }

  async DELETE_ACCOUNT() {
    var returnstatus;
    await this.tmmserviceServiceNatice.DELETE_ACCOUNT().then(result => {
      //console.log("result status ", result.status);
      //console.log("sosto message? ",this.gettranslation("PROFILE_DELETE_ACCOUNT_CONTACT_SUPPORT"));
      if (result.status != 0) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        if(result.status=="999" || result.status==999){
          this.genericAlert(this.gettranslation("PROFILE_DELETE_ACCOUNT_CONTACT_SUPPORT"));
        }else{
          let jsonData = result.data;
          this.genericAlert(jsonData.responseDescription);
        }
      }
      returnstatus = result.status;
    });
    return returnstatus;
  }


  async CHANGE_PASSWORD(username, OTP, password): Promise<any> {
    let loading = await this.loadingController.create();
    await loading.present();
    var returnResult;
    await this.tmmserviceServiceNatice.CHANGE_PASSWORD(username, OTP, password).then(result => {
      returnResult = result;
    });
    loading.dismiss()
    return returnResult;
  }

  async VERIFY_USER(mobileNumber, OTP): Promise<any> {
    let loading = await this.loadingController.create();
    await loading.present();
    var returnstatus;
    await this.tmmserviceServiceNatice
      .VERIFY_USER(mobileNumber, OTP)
      .then((result) => {
        returnstatus = result.status;
      });
    loading.dismiss();
    return returnstatus;
  }
  async REGISTER(mobileNumber, password, first_name, last_name): Promise<any> {
    let loading = await this.loadingController.create();
    await loading.present();
    var returnstatus;
    await this.tmmserviceServiceNatice.REGISTER(mobileNumber, password, first_name, last_name).then(result => {
      if (result.status != 0) {
        let jsonData = result.data;
        if(jsonData.responseCode=="699"){
          result.status=2;
        }else{
          this.genericAlert(jsonData.responseDescription);
        }
      }
      returnstatus = result.status;
    });
    loading.dismiss();
    return returnstatus;

  }
  async REGISTER_LOYALTY_USER(
    first_name,
    last_name,
    gender,
    nationality,
    newsletter,
    email,
    date_of_birth,
    shortcode
  ): Promise<any> {
    //console.log("the register gender ", gender);
    var returnstatus;
    await this.tmmserviceServiceNatice.REGISTER_LOYALTY(
      first_name,
      last_name,
      gender,
      nationality,
      newsletter,
      email,
      // favorite_store,
      date_of_birth,
      shortcode
    ).then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        this.REGISTERUSER = jsonData;
        // this.jsonObjectForUpdate.loyaltyRegistered = true;
        returnstatus = result.status;
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }
      else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        returnstatus = result.status;
      }
    });
    return returnstatus;
  }

  async UPDATE_PROFILE(fieldNameString, fieldNameValue): Promise<any> {
    //console.log("update profile?", fieldNameString, fieldNameValue )
    var returnstatus;
    await this.tmmserviceServiceNatice.UPDATE_PROFILE(fieldNameString, fieldNameValue).then(async result => {
      //console.log("result status? ", result.status);
      if (result.status == 0) {
        this.authService.userDetails[fieldNameString] = fieldNameValue;            
      } else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }
      else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }
      returnstatus = result.status;
    });
    return returnstatus;
  }

 
  async GET_FLYERS_PREVIEW(lon, lat): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.GET_FLYERS(lon, lat).then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        this.GET_FLYERS_LIST = jsonData.responseBody.promoFlyers;
        var str = JSON.stringify(this.GET_FLYERS_LIST);
        str = str.replace(/\"thumbnailLink\":/g, "\"thumbnail_link\":");
        str = str.replace(/\"pdfLink\":/g, "\"pdf_link\":");
        this.GET_FLYERS_LIST = JSON.parse(str);
      }else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }
      returnstatus = result.status;
    });
    return returnstatus;
  }

  async GET_EXTERNAL_APPLEWALLET_URI(): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.GET_EXTERNAL_APPLEWALLET_URI().then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        this.GET_SMASH_WALLET_URL = jsonData.responseBody;
      } else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }
      returnstatus = result.status;
    });
    return returnstatus;
  }

  



  async NOTIFY_CHECK_LOYALTY_NUMBER(shortcode): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.CHECK_LOYALTY_NUMBER(shortcode).then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        this.shortCodestatus = jsonData.responseBody.smashAccount.isAvailableForLinking;
        //console.log("shortCodestatus ", this.shortCodestatus)
        returnstatus = result.status;
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }else {
        let jsonData = result.data;
        // this.genericAlert(jsonData.responseDescription);
        returnstatus = result.status;
      }
    });
    return returnstatus;
  }

  async GET_GIFTCARD_BALANCE(reference): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.GET_GIFTCARD_BALANCE(reference).then(async result => {
      //in case there is no account the result will be empty list 
      //should i present an error in case of an empty list?
      if (result.status == 0) {
        let jsonData = result.data
        this.GET_GIFTCARD_BALANCE_LIST = jsonData.responseBody.smashAccount;
        // var str = JSON.stringify(this.GET_GIFTCARD_BALANCE_LIST);
        // this.GET_GIFTCARD_BALANCE_LIST = JSON.parse(str);
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        // this.genericAlert(jsonData.responseDescription);
      }
      returnstatus = result.status;
    });
    return returnstatus;
  }

  async GET_REDEMPTION_TIERS(): Promise<any> {
    var returnstatus;
    //console.log(this.GET_REDEMPTION_TIERS);
    await this.tmmserviceServiceNatice.GET_REDEMPTION_TIERS().then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        this.GET_REDEMPTION_TIERS_LIST = jsonData.responseBody.redemptionTiers;
        returnstatus = result.status;


      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        returnstatus = result.status;

      }
      //console.log("returnstatus ", returnstatus);

    });
    //console.log("returnstatus? ", returnstatus);
    return returnstatus;
  }

  async GET_CHARITY_ORGANIZATIONS(): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.GET_CHARITY_ORGANIZATIONS().then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        this.GET_CHARITY_ORGANIZATIONS_LIST = jsonData.responseBody.charityOrganizations;
      }
      else if (result.status == -1) {
        //console.log("charity result", result);
        //console.log(result.data);
        //console.log(result.data);
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
      }
      returnstatus = result.status;

    });
    return returnstatus;
  }

  async SUBMIT_ORDER(orderid, ordertype, amount, currency, account_to): Promise<any> {
    var returnstatus;
    await this.tmmserviceServiceNatice.SUBMIT_ORDER(orderid, ordertype, amount, currency, account_to).then(async result => {
      if (result.status == 0) {
        returnstatus = result.status;
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        returnstatus = result.status;

      }
    });
    return returnstatus;
  }

  async PREPARE_ORDER(ordertype, amount, currency, organization): Promise<any> {
    await this.tmmserviceServiceNatice.PREPARE_ORDER(ordertype, amount, currency, organization).then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        if (ordertype == "DONATION") {
          this.orderiddonation = jsonData.responseBody.smashOrder.id;
          this.resultcodedonation = result.status;
        }
        else {
          this.orderidredemption = jsonData.responseBody.smashOrder.id;
          this.resultcoderedemption = result.status;
        }
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      }else {
          let jsonData = result.data;
          this.genericAlert(jsonData.responseDescription);
        }
      });
    if (ordertype == "DONATION") {
      return this.resultcodedonation;
    } else {
      return this.resultcoderedemption;
    }
  }


  /**OTHER GENERAL METHODS**/
  navigate_to_external_link(LINK) {
    const browser = this.iab.create(LINK, "_system", "location=yes");
  }
  async presentModal(modalContent) {
    const modal = await this.modalController.create({
      component: ModalpageComponent,
      componentProps: {
        pageHTML: modalContent,
      },
      // cssClass: 'my-custom-class',
      swipeToClose: true,
      presentingElement: await this.modalController.getTop(), // Get the top-most ion-modal
    });
    return await modal.present();
  }
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: "PLEASE_WAIT",
      duration: 5000
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
    ////console.log('Loading dismissed!');
  }
  navigateback(backpage) {
    this.router.navigate([backpage]);
  }
  navigate(page, navigationExtras) {
    this.router.navigate([page], navigationExtras);
  }

  public toggleInputTextPassword(): void {
    this.isActiveToggleTextPassword =
      this.isActiveToggleTextPassword == true ? false : true;
  }

  public getInputType() {
    return this.isActiveToggleTextPassword ? "password" : "text";
  }

  public getInputIconName() {
    return this.isActiveToggleTextPassword ? 'eye-off-outline' : 'eye';
  }
  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  //You can use either cssClassToast or a Predefined color
  //Using only cssClassToast:
  //this.generalmethods.presentToast("This is a message", 0, "my-toast-css", "");
  //Using only a Predefined color example:
  //this.generalmethods.presentToast("This is a message", 0, "", "danger");
  async presentToast(message, duration, cssClassToast, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: "bottom",
      cssClass: cssClassToast,
      color: color,
      buttons: [
        {
          text: "X",
          role: "cancel",
        },
      ],
    });
    toast.present();
  }

  //Function to dismiss a toast message
  dismissToast() {
    this.toastController.dismiss();
  }

  predicateBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  changelanguage(selectedlang) {
    if (this.runningplatform == "web") {
      this.cookieService.set("_icl_current_language", selectedlang);
    }
    this.translate.use(selectedlang).subscribe((res) => {
      this.languageChange.next(selectedlang);
    });
    this.tmmserviceServiceNatice.Lang = selectedlang;
    if (selectedlang == "ar") {
      this.direction = "rtl";
      document.documentElement.dir = "rtl";
    } else if (selectedlang == "en") {
      this.direction = "ltr";
      document.documentElement.dir = "ltr";
    }
  }

  setdefaultlanguage(selectedlang) {
    this.translate.setDefaultLang(selectedlang);
    if (selectedlang == "ar") {
      this.direction = "rtl";
    } else if (selectedlang == "en") {
      this.direction = "ltr";
    }
  }
  gettranslation(text) {
    var trans;
    this.translate.get(text).subscribe((res: string) => {
      trans = res;
    });
    return trans;
  }

  setAppLanguage(languagevalue) {
    this.setdefaultlanguage(languagevalue);
    this.changelanguage(languagevalue);
    if (languagevalue == "ar") {
      this.direction = "rtl";
    } else if (languagevalue == "en") {
      this.direction = "ltr";
    }

    if(languagevalue == "ar"){
      // this.selectedlaguage = ar
      this.direction = "rtl"
    }
    else if(languagevalue == "en"){
      // this.selectedlaguage = enUS
      this.direction = "ltr"
    }
  }

  currentlanguage() {
    var language;
    if (this.translate.currentLang == "default") {
      language = String(navigator.language.split("-", 1));
      if (language != "ar") {
        language = "en";
      }
    } else {
      language = this.translate.currentLang;
    }
    return language;
  }
  async GET_NOTIFICATIONS(messageType, isRead, cursor, size, isCounterOnly): Promise<any> {
    //console.log("GET NOTIFICATIONS");
    var returnstatus;
    await this.tmmserviceServiceNatice.GET_NOTIFICATIONS(messageType, isRead, cursor, size, isCounterOnly).then(async result => {
      if (result.status == 0) {
        returnstatus = result.status;
        let jsonData = result.data
        if(isCounterOnly)
          this.GET_NOTIFICATION_SIZE = jsonData.responseBody.size;
        else{
          this.GET_NOTIFICATION_LIST = jsonData.responseBody.smashMemberNotifications;
          this.GET_NOTIFICATION_CURSOR = jsonData.responseBody.cursor;
        }
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        returnstatus = 1;
      }
    });
    return returnstatus;
  }

  async UPDATE_NOTIFICATION(id, isRead): Promise<any> {
    var updatestatus;
    await this.tmmserviceServiceNatice.UPDATE_NOTIFICATION(id, isRead).then(async result => {
      if (result.status == 0) {
        updatestatus = result.status;
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        updatestatus = result.status;
      }
    });
    return updatestatus;
  }


 
  async GET_KIDS(): Promise<any> {
    //console.log("mpeni stin get kids");
    var status;
    await this.tmmserviceServiceNatice.GET_KIDS().then(async result => {
      if (result.status == 0) {
        let jsonData = result.data
        //console.log("kids ", jsonData);
        status = result.status;
        this.GET_KIDS_LIST= jsonData.responseBody.smashMemberDependants;
      }
      else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        status = result.status;
      }
    });
    return status;
  }
  async CREATE_KID(kidDetails: Kid): Promise<any> {
    var status;
    await this.tmmserviceServiceNatice.CREATE_KID(kidDetails).then(async result => {
      if (result.status == 0) {
        status = result.status;
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        status = result.status;
      }
    });
    return status;
  }
  async DELETE_KID(id): Promise<any> {
    var status;
    await this.tmmserviceServiceNatice.DELETE_KID(id).then(async result => {
      if (result.status == 0) {
        status = result.status;
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        status = result.status;
      }
    });
    return status;
  }
  async UPDATE_KID(kid:Kid): Promise<any> {
    var status;
    await this.tmmserviceServiceNatice.UPDATE_KID(kid).then(async result => {
      if (result.status == 0) {
        status = result.status;
      }else if (result.status == -1) {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        this.VALIDATE_SESSION();
      } else {
        let jsonData = result.data;
        this.genericAlert(jsonData.responseDescription);
        status = result.status;
      }
    });
    return status;
  }
}
